import React from "react";

export interface WrapperProps {
    sectionProps?: object,
    contentClasses?: string,
    headline?: string,
    styles?:any,
    textStyles?:any
}

const Wrapper : React.FC<WrapperProps> = (props) => {

    const {children, sectionProps = {}, contentClasses = "", headline = "" } = props;

return (
<section className="section-container" {...sectionProps}>
   <div className={`content-wrapper animated fadeIn faster ${contentClasses}`}>
       { (headline || "").length > 0 ? <div className="content-heading d-block" style={props.styles}>
            <h2 style={props.textStyles}>
                { headline }
                </h2>
        </div> : "" }
    {children}
   </div>
</section>)
}
export default Wrapper;