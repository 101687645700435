import { makeAutoObservable, runInAction,toJS } from "mobx"
import { Unwrap } from "../utils/api/apiClient";
import { RootStore } from "./RootStore";
// import { Country } from "../models/Country";
import BlockHelper from "./BlockHelper"
import { Supplier } from "../models/Supplier";
// import { EuLegalAct } from "../models/EuLegalAct";
// import { CustomLegalAct } from "../models/CustomLegalAct";


export default class SharedDataStore {
    rootStore : RootStore;
    private _suppliers:Supplier[] | null = null;
    // private _countries: Country[] | null = null;
    // private _euLegalActs: EuLegalAct[] | null = null;
    // private _customLegalActs: CustomLegalAct[] | null = null;
    private _blockHelper = new BlockHelper();

    constructor(rootStore : RootStore) {
        makeAutoObservable(this, { rootStore: false, _blockHelper: false,getSuppliers: false } as any);
        this.rootStore = rootStore;
    }

    get suppliers(){
        this.getSuppliers();
        return toJS(this._suppliers);
    }

    private async getSuppliers() {
        if (this._suppliers != null || !this._blockHelper.tryBlock("suppliers")) return;

        let res = await this.rootStore.apiClient.get("Suppliers/allavailable");
        let suppliers:any = Unwrap.result<Supplier[]>(res);
        //console.log("suppliersssss",suppliers);
        runInAction(() => {
            this._suppliers = suppliers ?? [];
            this._blockHelper.unblock("suppliers");
        });
    }

    // get countries() {
    //     this.getCountries();
    //     return this._countries;
    // }

    // get legalActs() {
    //     this.getLegalActs();
    //     if (this._euLegalActs == null || this._customLegalActs == null) return null;
    //     return [...this._euLegalActs ?? [], ...this._customLegalActs ?? []];
    // }
    
    // get euLegalActs() {
    //     this.getLegalActs();
    //     return this._euLegalActs;
    // }

    // get customLegalActs() {
    //     this.getLegalActs();
    //     return this._customLegalActs;
    // }

    // private async getCountries() {
    //     if (this._countries != null || !this._blockHelper.tryBlock("countries")) return;

    //     let res = await this.rootStore.apiClient.get("countries");
    //     let countries = Unwrap.result<Country[]>(res);
    //     runInAction(() => {
    //         this._countries = countries ?? [];
    //         this._blockHelper.unblock("countries");
    //     });
    // }
    // private async getLegalActs() {
    //     if ((this._euLegalActs != null && this._customLegalActs != null) || !this._blockHelper.tryBlock("legalacts")) return;

    //     let [euRes, customRes] = await Promise.all([this.rootStore.apiClient.get("legalacts/eu"), this.rootStore.apiClient.get("legalacts/custom")]);

    //     let euLegalActs = Unwrap.result<EuLegalAct[]>(euRes);
    //     let customActs = Unwrap.result<CustomLegalAct[]>(customRes);
    //     runInAction(() => {
    //         this._euLegalActs = euLegalActs;
    //         this._customLegalActs = customActs;
    //         this._blockHelper.unblock("legalacts");
    //     });
    // }

    clear() {
        // this._countries = [];
        // this._euLegalActs = [];
        // this._customLegalActs = [];
        this._suppliers = [];
        this._blockHelper.clear();
    }

}