import ArticlesStore from "./ArticlesStore";
import SessionStore from "./SessionStore";
import { reaction } from "mobx";
import SharedDataStore from "./SharedDataStore";
import SupplierStore from "./SupplierStore";
import CustomFormStore from "./CustomFormStore"
import RequestsStore from "./RequestsStore";

export class RootStore {
    sessionStore : SessionStore;
    articlesStore : ArticlesStore;
    sharedDataStore : SharedDataStore;
    supplierStore:SupplierStore;
    customFormStore:CustomFormStore
    requestsStore:RequestsStore;
    constructor() {
        this.sessionStore = new SessionStore(this);
        this.articlesStore = new ArticlesStore(this);
        this.sharedDataStore = new SharedDataStore(this);
        this.supplierStore = new SupplierStore(this);
        this.customFormStore = new CustomFormStore(this);
        this.requestsStore =  new RequestsStore(this);
    }

    /**
     * Clear all data
     */
    clear() {
        this.articlesStore.clear();
        this.supplierStore.clear();
        this.requestsStore.clear();
        this.sessionStore.clear();
        this.sharedDataStore.clear();
    }

    /** 
     * Convinience method for sessionStore.apiClient
     **/
    get apiClient() {
        return this.sessionStore.apiClient;
    }
}

const RootStoreInstance = new RootStore();

reaction(() => {
    return { 
        userId: RootStoreInstance.sessionStore.user?.profile?.sub,
        spaceId: RootStoreInstance.sessionStore.currentSpace?.id
    }
}, ({userId}) => {
    RootStoreInstance.articlesStore.clear();
    RootStoreInstance.requestsStore.clear();
    if (!userId) RootStoreInstance.sessionStore.clear(); RootStoreInstance.supplierStore.clear();
}, { delay: 500 });

export default RootStoreInstance;