var config = {
  REACT_APP_AUTH_URL:             process.env.REACT_APP_AUTHORITY,
  apiUri:                         process.env.REACT_APP_API_URI,
  REACT_APP_IDENTITY_CLIENT_ID:   "clevercompliance.supplierportal",
  REACT_APP_REDIRECT_URL:         window.location.origin + "/account/signin-oidc",
  REACT_APP_SILENT_REDIRECT_URL:  window.location.origin + "/account/silent-renew",
  REACT_APP_LOGOFF_REDIRECT_URL:  window.location.origin + "/account/signout-oidc",
  supportEmail:                   "support@clevercompliance.io",
  generalEmail:                   "contact@clevercompliance.io"
};
if (!config.apiUri?.endsWith("/")) config.apiUri = `${config.apiUri}/`;
export default config;
