import React from "react";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import authService from "../../utils/AuthService";
import addUser from "../../images/illustrations/add-user.svg";
import authentication from "../../images/illustrations/authentication.svg";

export default class Unathenticated extends React.Component {
    render() {
        return <div className="container"><h4 className="text-dark text-center">
        We just want to know who you are before you can continue
        <div className="row highlight-purple align-items-center mt-3 py-3 clickable rounded" onClick={() => authService.startRegistration()}>
           <div className="col">
                Register now <ChevronRightIcon />
            </div> 
            <div className="col-4">
                <img src={addUser} alt="Register" className="img-fluid" />
            </div>
        </div>
        <div className="row highlight-primary align-items-center mt-5 py-3 clickable rounded" onClick={() => authService.signIn()}>
            <div className="col-4">
                <img src={authentication} alt="Sign in" className="img-fluid" />
            </div>
            <div className="col">
                Sign in <ChevronRightIcon />
            </div> 
        </div>
    </h4></div>
    }
}