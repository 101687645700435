import { makeAutoObservable} from "mobx"
import { Unwrap } from "../utils/api/apiClient";
import { RootStore } from "./RootStore";
import BlockHelper from "./BlockHelper";
import {Requests} from "../models/Requests";


export default class RequestsStore {
    rootStore : RootStore;
    private _blockHelper = new BlockHelper();
    constructor(rootStore : RootStore) {
        makeAutoObservable(this, { rootStore: false} as any);
        this.rootStore = rootStore;
    }

    
    async addRequests(request:Requests){
        let res = await this.rootStore.apiClient.post("RequestForInformations",request);
        let requestsResponse = Unwrap.result<Requests>(res)!;
        if(requestsResponse.id){
            return requestsResponse;
        }
        return false; // need to set validate meesage coming from apis
    }

    // async updateSupplier(supplier:Supplier){
    //     let res = await this.rootStore.apiClient.put(`Suppliers`,supplier);
    //     let updatedSupplier =  Unwrap.result<Supplier>(res)!;
    //     if(updatedSupplier.id){
    //         return updatedSupplier;
    //     }
    //     return false;
    // }

    // async deleteSupplier(supplier:Supplier){
    //     await this.rootStore.apiClient.delete(`Suppliers/${supplier.id}`);
    // }

    clear() {
        //this._suppliers = [];
        this._blockHelper.clear();
    }
}