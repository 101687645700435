import { makeAutoObservable } from "mobx"
import { Unwrap } from "../utils/api/apiClient";
import { RootStore } from "./RootStore";
import BlockHelper from "./BlockHelper";
import { Articles } from "../models/Article";

export class NotFoundProduct {
    id: string;
    constructor(id : string) {
        this.id = id;
    }
}

export default class ArticlesStore {
    rootStore : RootStore;
    // private _products: (Product|NotFoundProduct)[] = [];
    private _blockHelper = new BlockHelper();

    constructor(rootStore : RootStore) {
        makeAutoObservable(this, { rootStore: false } as any);
        this.rootStore = rootStore;
    }


    async addArticles(articles:Articles){
        let res = await this.rootStore.apiClient.post("Articles",articles);
        let articleResponse = Unwrap.result<Articles>(res)!;
        if(articleResponse.id){
            return articleResponse;
        }
        return false; // need to set validate meesage coming from apis
    }

    async updateArticle(articles:Articles){
        let res = await this.rootStore.apiClient.put(`Articles`,articles);
        let updatedArticle =  Unwrap.result<Articles>(res)!;
        if(updatedArticle.id){
            return updatedArticle;
        }
        return false;
    }
    async deleteArticle(articles:Articles){
        await this.rootStore.apiClient.delete(`Articles/${articles.id}`);
    }
    
    
    // isProduct(p : Product | NotFoundProduct | null | undefined) : p is Product {
    //     return (p as Product)?.name !== undefined;
    // }
    // isNotFoundProduct(p : Product | NotFoundProduct | null | undefined) : p is NotFoundProduct {
    //     if (p === null || p === undefined) return false;
    //     return (p as Product)?.name === undefined;
    // }

    // /**
    //  * Check if the param is of Product type, not anything else (NotFoundProduct or falsey). 
    //  * @param p A potential product
    //  * @returns A product if it's a Product type, otherwise null
    //  */
    // ifProduct(p : Product | NotFoundProduct | null | undefined) {
    //     return this.isProduct(p) ? p : null;
    // }

    // cacheProduct(product : Product | NotFoundProduct, overwrite = true) {
    //     let found = this._products.findIndex(a => a.id === product.id);
    //     if (!overwrite && found > -1) return;

    //     if (found > -1) this._products[found] = product;
    //     else this._products.push(product);
    // }

    clear() {
        // this._products = [];
        this._blockHelper.clear();
    }

    // async putProduct(product : Product) {
    //     this._blockHelper.tryBlock(product.id); //Note that we don't care about if it's already blocked, we will put this regardless. But if we can prevent some fetches while we put it, that's at least something.
    //     await this.rootStore.apiClient.put("products", product);
    //     runInAction(() => {
    //         this.cacheProduct(product);
    //     });
    // }

    // removeCachedProduct(id : string) {
    //     this._products = this._products.filter(a => a.id !== id);
    // }

    // private async fetchProduct(id : string) {
    //     if (!this._blockHelper.tryBlock(id)) return;
    //     let res = await this.rootStore.apiClient.get(`products/${id}`);
    //     let product = Unwrap.result<Product>(res);
    //     runInAction(() => {
    //         this._blockHelper.unblock(id);
    //         this.cacheProduct(product ?? new NotFoundProduct(id));
    //     })
    // }

    // getProduct(id : string | null | undefined, bypassCache = false) : Product | NotFoundProduct | null | undefined {
    //     if (!id) return null;
    //     let product : Product | undefined | NotFoundProduct = undefined;
    //     //If we're bypassing the cache, or if we can't find the product in the cache, let's get it.
    //     if (bypassCache || !(product = this._products?.find(a => a.id === id))) {
    //         this.fetchProduct(id);
    //     }
    //     return product;
    // }

}