import React from "react";
import Wrapper, { WrapperProps } from "./Wrapper";

interface Loading {
    isLoading: boolean,
    text: string   
}

interface Props {
    loaders: Loading[]
}

export const LoadingWrapper = function({ loaders, children, wrapperProps } : { loaders:  Loading[], children?: any, wrapperProps?: WrapperProps } ) {
    
    const isLoading = loaders.some(a => a.isLoading);

    if (!isLoading) return <Wrapper {...wrapperProps}>{children}</Wrapper>
    return <Wrapper {...wrapperProps} >
        <div className="d-flex justify-content-center align-items-center h-100" >
            <div>
                {loaders.map((a, index) => <div key={index} style={ { fontSize: "120%", minHeight: '40px', animationDelay: `${index*150}ms` } } className="animated fadeInUp d-flex align-items-center"><span className="flex-grow-1 animated pulse infinite">{a.text}</span> <span style={ { minWidth: '75px' } }>{a.isLoading ? "" : <span className="mb-0 ml-3 alert alert-success py-1 px-2 d-inline-block animated fadeInUp faster">Done</span>}</span></div>)}
            </div>
        </div>
    </Wrapper>

}