import  React from "react"
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import StoreIcon from '@material-ui/icons/Store';
import ListIcon from '@material-ui/icons/List';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import { NavLink } from 'react-router-dom'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import "../../styles/Sidebar.css";

import SpaceSelector from "../Admin/SpaceSelector";
import useRootStore from "../../hooks/rootStoreHook";
import { observer } from "mobx-react-lite";

const Sidebar = observer(function Sidebar() {
   const { sessionStore } = useRootStore();

   const menu = () => {
      return <ul className="sidebar-nav">
      {
         sessionStore.user ?
            <li className="bg-green">
               <div className="d-flex text-white p-2 ml-3" style={{ "cursor": "pointer" }}>
                  <OverlayTrigger placement="top" overlay={<Tooltip id="user-tooltip">{sessionStore.user.profile.email}</Tooltip>}>
                        <span>
                           <AccountCircleIcon color="inherit" fontSize="small" className=" mr-2" />
                              { sessionStore.user.profile.given_name + " " + sessionStore.user.profile.family_name}
                        </span>
                  </OverlayTrigger>
               </div>
            </li> : null 
      }
         <li>
            <NavLink to="/" exact title="Dashboard" style={{ "whiteSpace": "normal" }} className="d-flex align-items-center">
               <MultilineChartIcon color="inherit" fontSize="small" className=" mr-2" />
               <span>Dashboard</span>
            </NavLink>
         </li>
         <li>
            <NavLink to="/articles" exact title="Articles" style={{ "whiteSpace": "normal" }} className="d-flex align-items-center">
               <ListIcon color="inherit" fontSize="small" className=" mr-2" />
               <span>Articles</span>
            </NavLink>
         </li>
         <li>
            <NavLink to="/suppliers" exact title="Suppliers" style={{ "whiteSpace": "normal" }} className="d-flex align-items-center">
               <StoreIcon color="inherit" fontSize="small" className=" mr-2" />
               <span>Suppliers</span>
            </NavLink>
         </li>
         <li>
            <NavLink to="/forms" exact title="Articles" style={{ "whiteSpace": "normal" }} className="d-flex align-items-center">
               <ChromeReaderModeIcon color="inherit" fontSize="small" className=" mr-2" />
               <span>Forms</span>
            </NavLink>
         </li>
         <li>
            <NavLink to="/requests" exact title="Articles" style={{ "whiteSpace": "normal" }} className="d-flex align-items-center">
               <QuestionAnswerIcon color="inherit" fontSize="small" className=" mr-2" />
               <span>Requests</span>
            </NavLink>
         </li>
         <li>
            <NavLink to="/reports" exact title="Articles" style={{ "whiteSpace": "normal" }} className="d-flex align-items-center">
               <FindInPageIcon color="inherit" fontSize="small" className=" mr-2" />
               <span>Reports</span>
            </NavLink>
         </li>
      </ul>
   }

   const signInPrompt = () => {
      return <div className="d-flex justify-content-center align-items-center flex-column mt-2">
         <div>
            Please sign in
         </div>
         <Button onClick={sessionStore.signIn} className="btn-oval mt-2">Sign in</Button>
      </div>
   }

   return (
      <aside className="aside-container" style={{ transition: "opacity 0.5s ease 0s", opacity: "1" }}>
         <div className="aside-inner">
            { !!sessionStore.hasSpaces ? <SpaceSelector /> : null }
            <nav data-sidebar-anyclick-close="" className="sidebar">
               { sessionStore.authenticated ? menu() : signInPrompt() }
               </nav>
         </div>
      </aside>);
});
export default Sidebar
