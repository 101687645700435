// import React, { ComponentClass } from "react";
// import { Route, RouteProps } from "react-router";
// import Wrapper from "../Wrapper";
// import { AuthConsumer } from "./AuthProvider";
// import Unauthenticated from "./Unauthenticated";

// export default class AuthenticatedRoute extends React.Component<RouteProps> {
//     render() {
//         let props = {...this.props, ...{component: this.props.component }};
//         let { component, ...rest } = this.props;
//         let Component = component as ComponentClass;
//         return <AuthConsumer>
//             {
//                 context => (
//                     <Route {  ...rest } render={(props) => (
//                         context!.state.authenticated ? <Component /> : <Wrapper><Unauthenticated></Unauthenticated></Wrapper>
//                     )} />
//                 )
//             }
//         </AuthConsumer>
//     }
// }

import { observer } from "mobx-react-lite";
import React, { ComponentClass } from "react";
import { Route, RouteProps } from "react-router";
import useRootStore from "../../hooks/rootStoreHook";
import Wrapper from "../Wrapper";
import Unauthenticated from "./Unauthenticated";

export default observer(function AuthenticatedRoute(props : RouteProps) {
    
        const { component, ...rest } = props;
        const Component = component as ComponentClass;
        const { sessionStore } = useRootStore()
        if(sessionStore.authenticated){
            return <Route {  ...rest } render={() => (<Component /> )} />
        }else{
            return <Route {  ...rest } render={() => ( <Wrapper><Unauthenticated></Unauthenticated></Wrapper>)} />
       }

});