import React from "react";
import { Link } from "react-router-dom";
// import "../../styles/header.css"
import brandlogo from "../../images/clever-compliance.png"
import MenuIcon from '@material-ui/icons/Menu';
// import { registerProductFeedWidget, unregisterProductFeedWidget } from "../../utils/productFeed";
import useRootStore from "../../hooks/rootStoreHook";
import { observer } from "mobx-react-lite";

const Header = observer(function Header() {

    const { sessionStore } = useRootStore();

    //TODO: Should be done differently
    function toggleSidebar() {
      document.body.classList.toggle("aside-toggled");
    }

    // useEffect(() => {
    //   registerProductFeedWidget();

    //   return unregisterProductFeedWidget;
    // }, []);

    return (
      <header className="topnavbar-wrapper">
        <nav className="navbar topnavbar justify-content-between px-2 px-md-0">
          <div className="navbar-header pl-md-0">
            <Link to="/">
              <div className="brand-logo">
                <img
                  src={brandlogo}
                  alt="Clever Compliance"
                  title="Clever Compliance"
                  className="img-fluid"
                />
              </div>
            </Link>
          </div>
          <ul className="navbar-nav flex-row">
            <li className="nav-item">
              <p onClick={toggleSidebar} className="d-flex h-100 align-items-center nav-link sidebar-toggle px-4 d-md-none">
                <MenuIcon style={{ fill: "#fff" }} />
              </p>
            </li>
          </ul>
          <ul className="navbar-nav flex-row mr-3">
            <li className="nav-item d-flex align-items-center position-relative mr-5 pr-4">
              <p id="productfeed-trigger" className="text-white">What's new</p>
            </li>
            <li className="nav-item d-flex align-items-center">
                  { !sessionStore.authenticated ?
                    <button type="button" className="btn d-inline-flex btn-oval btn-outline-light" onClick={() => { sessionStore.signIn();} }>Sign in</button>
                    :
                    <button type="button" className="btn d-inline-flex btn-oval btn-outline-light" onClick={() => { sessionStore.signOut();} }>Sign out</button>
                  }
            </li>
          </ul>
        </nav>
      </header>
    );
});

export default Header;
